// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-R1J7QH1294');
};

export const logPageView = () => {
  ReactGA.send('pageview');
};
