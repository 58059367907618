import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { initGA, logPageView } from './analytics';
import App from './App';

initGA();

const Root = () => {
  useEffect(() => {
    logPageView();
  }, []);

  return <App />;
};

ReactDOM.render(<Root />, document.getElementById('root'));
